// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UinUzN6dW"];

const serializationHash = "framer-6p0Ps"

const variantClassNames = {UinUzN6dW: "framer-v-8h8ztn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, height, id, link, title, width, ...props}) => { return {...props, cduyS1Ada: link ?? props.cduyS1Ada, yoHhge7xS: title ?? props.yoHhge7xS ?? "Get All Templates for $89", z1SCMIK__: background ?? props.z1SCMIK__ ?? "rgb(17, 17, 19)"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cduyS1Ada, yoHhge7xS, z1SCMIK__, azjtH6I4yQtSlkE_ny, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "UinUzN6dW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={cduyS1Ada} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8h8ztn", className, classNames)} framer-179l4c5`} data-framer-name={"Remove This Button"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"UinUzN6dW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: z1SCMIK__, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 0.6021873017743928px 0.421531111242075px -1px rgba(0, 0, 0, 0.11759), 0px 2.288533303243457px 1.60197331227042px -2px rgba(0, 0, 0, 0.11085), 0px 10px 7px -3px rgba(0, 0, 0, 0.08)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-azjtH6I4y-QtSlkE_ny))"}}>Get All Templates for $89</motion.p></React.Fragment>} className={"framer-1seg4s6"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"ekTKFam6F"} style={{"--extracted-r6o4lv": "var(--variable-reference-azjtH6I4y-QtSlkE_ny)", "--framer-paragraph-spacing": "0px", "--variable-reference-azjtH6I4y-QtSlkE_ny": azjtH6I4yQtSlkE_ny}} text={yoHhge7xS} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6p0Ps.framer-179l4c5, .framer-6p0Ps .framer-179l4c5 { display: block; }", ".framer-6p0Ps.framer-8h8ztn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 14px 20px 14px 20px; position: relative; text-decoration: none; width: min-content; }", ".framer-6p0Ps .framer-1seg4s6 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6p0Ps.framer-8h8ztn { gap: 0px; } .framer-6p0Ps.framer-8h8ztn > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-6p0Ps.framer-8h8ztn > :first-child { margin-left: 0px; } .framer-6p0Ps.framer-8h8ztn > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 225
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"cduyS1Ada":"link","yoHhge7xS":"title","z1SCMIK__":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkLXvSKSz1: React.ComponentType<Props> = withCSS(Component, css, "framer-6p0Ps") as typeof Component;
export default FramerkLXvSKSz1;

FramerkLXvSKSz1.displayName = "Buy Template Button Copy";

FramerkLXvSKSz1.defaultProps = {height: 46, width: 225};

addPropertyControls(FramerkLXvSKSz1, {cduyS1Ada: {title: "Link", type: ControlType.Link}, yoHhge7xS: {defaultValue: "Get All Templates for $89", displayTextArea: false, title: "Title", type: ControlType.String}, z1SCMIK__: {defaultValue: "rgb(17, 17, 19)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerkLXvSKSz1, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZ1rib2Bg-4.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})